const lineHeights = {
  XXSMALL: '1',
  XSMALL: '1.15',
  SMALL: '1.25',
  MEDIUM: '1.375',
  LARGE: '1.75',
  XLARGE: '2.5',
};

export default lineHeights;
