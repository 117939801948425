import Anchor from './Anchor';
import Button from './Button';
import Heading from './Heading';

const objects = {
  Anchor,
  Button,
  Heading,
};

export default objects;
